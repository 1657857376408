$assetsPath: '//cdn.switchfly.com/static/c4ca002/resources/shopping/images/themes/atlantispi';

// Grays
// -------------------------

$gray-dark: #1b1b1b;
$gray: lighten($gray-dark, 10%); // #353535
$gray-light: lighten($gray-dark, 74%); // #d9d9d9
$gray-medium-light: lighten($gray-dark, 49%); // #999
$gray-lighter: lighten($gray-dark, 83%); // #f0f0f0
$almost-white: #f7f7f7;
$navy: #033C5C;

// Brand colors
// -------------------------

$brand-primary: #1073ac;
$brand-success: #00ba17;
$brand-warning: #e97600;
$brand-danger: #d93734;
$brand-info: #5bc0de;
$brand-special: #66cbff;

// Scaffolding
// -------------------------

$text-color: #555;

// Typography
// -------------------------

$font-family-sans-serif: Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 14px;

$font-size-h1: ceil($font-size-base * 1.85); // ~26px
$font-size-h2: ceil($font-size-base * 1.42); // ~20px
$font-size-h3: $font-size-base; // ~14px
$font-size-h4: $font-size-h3; // ~14px
$font-size-h5: floor($font-size-base * 0.85); // ~12px
$font-size-h6: $font-size-h5;

$headings-font-weight: bold;

// Buttons
// -------------------------

$btn-border-radius: 0;

$btn-primary-text: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-bg-color-hover: darken($btn-primary-bg, 8%);

$btn-default-color: #fff;
$btn-default-bg: $brand-primary;
$btn-default-bg-color-hover: darken($btn-primary-bg, 8%);

$btn-secondary-text: #fff;
$btn-secondary-bg: $brand-primary;
$btn-secondary-bg-color-hover: darken($btn-primary-bg, 8%);


// Global
// -------------------------

$site-header-background: #fff;

$site-header-border-bottom-width: 0px;

$site-header-logo-primary-file-path: "#{$assetsPath}/logo_primary.png";

$site-footer-text-color: #fff;

$site-footer-link-color: #fff;

$site-header-logo-primary-min-height: 55px;

// Page Level Styles
// -------------------------

$section-header-border-bottom-color: $gray-light;

// Page Aside Contents Container
// -------------------------

$aside-contents-container-background-color: #fff;

$aside-contents-section-background-color: #ededed;

$aside-contents-section-top-header-background-color: $brand-primary;
$aside-contents-section-top-header-text-color: #fff;

// Points Slider Component
// -------------------------

$points-slider-selection-background-color: $brand-primary;
$points-slider-handle-text-color: $points-slider-selection-background-color;

// Filter Components
// -------------------------
$filter-slider-selection-background-color: $brand-primary;
$filter-slider-handle-background-color: $brand-primary;
$points-slider-selection-background-color: $brand-primary;


// Product Cards
// -------------------------\
$product-body-bg-color-xsell: lighten($brand-special, 40%);
$product-body-border-color-xsell: $brand-special;
$rate-deal-bg-color: $brand-special;
$rate-deal-text-color: dynamicColor($brand-special);

// Pagination
// -------------------------
$pagination-active-bg: $brand-primary;


// DashCart
// -------------------------
$dashcart-product-header-background-color-xsell: $brand-special;


// Site Header
// -------------------------
$site-header-brand-container-col-width-handheld: 12;
$site-header-loyalty-container-col-width-handheld: 0;
$subheader-toolbar-row-background-color_handheld: $navy;
$subheader-toolbar-row-anchor-color_handheld: #3CABEC;

// Site Footer
// -------------------------

$site-footer-background-color: #171717;
$site-footer-background-color_handheld: $site-footer-background-color;

// Custom CSS
// -------------------------

.main-contents-container {
  min-height: 290px;
}

#site-footer {
  color: #737373;
  a {
    color: #737373;
  }
}

.case-base {
  #site-header {
    border: 0;

    .header-brand-container {
      .logo-primary {
        min-width: 206px;
        background-position: center center;
      }
    }
  }
}

.case-handheld {
  #site-header {
    .header-brand-container {
      .logo-primary {
        background-position: top center;
      }
    }
  }

  #site-footer {
    border-top: none;
    color: #fff;
    text-align: center;

    a {
      color: #fff;
    }

    .list-inline li {
      border-right: 1px solid $gray-medium-light;
      padding: 0 5px;

      &:last-of-type {
        border-right: none;
      }
    }
  }
}

@mixin interstitialImage($imageLanguage) {
  background: url("../../../../../../../images_w/atlantispi/nav/default/loading-atlantis_" + $imageLanguage + ".gif") center 20px no-repeat;
  background-size: 55%;
  padding-top: 30%;

}

#interstitial-container {
  @include interstitialImage("en");

  .circle-loader {
    @extend .sr-only;
  }
}

.lang-es {
  #interstitial-container {
    @include interstitialImage("es");
  }
}

.lang-pt {
  #interstitial-container {
    @include interstitialImage("pt");
  }
}

@media (max-width: 480px) {
  #interstitial-container {
    background-size: 95%;
    padding-top: 50%;
  }
}
